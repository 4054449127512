import React from 'react';
import PropTypes from 'prop-types';
import { storyblokEditable } from 'gatsby-source-storyblok';

import NumberedHeading from '../NumberedHeading';

const DynamicNumberedHeading = ({ blok }) => (
  <NumberedHeading {...storyblokEditable(blok)} {...blok} />
);

DynamicNumberedHeading.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default DynamicNumberedHeading;
