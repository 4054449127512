import React from 'react';
import PropTypes from 'prop-types';
import { Box, Heading } from 'grommet';

import useMobile from './useMobile';
import NumberedBullet from './NumberedBullet';
import SmartLink from './SmartLink';

const NumberedHeading = ({
  number,
  bulletSize = 'medium',
  gap = 'small',
  text,
  headingLevel,
  verticalPad,
  horizontalPad,
  link,
}) => {
  const isMobile = useMobile();
  const rootedUrl = link
    ? link.linktype === 'email'
      ? `mailto:${link.url}`
      : link.linktype === 'story'
      ? `/${link.cached_url}`
      : link.cached_url
    : undefined;

  return (
    <SmartLink to={rootedUrl} plain>
      <Box
        direction={isMobile ? 'row' : 'column'}
        gap={gap}
        align="center"
        fill="horizontal"
        pad={{ vertical: verticalPad, horizontal: horizontalPad }}
      >
        <NumberedBullet size={bulletSize} number={number} />
        <Heading level={headingLevel} textAlign={isMobile ? 'start' : 'center'}>
          {text}
        </Heading>
      </Box>
    </SmartLink>
  );
};

NumberedHeading.propTypes = {
  number: PropTypes.number,
  bulletSize: PropTypes.oneOf(['small', 'medium', 'large', 'xlarge']),
  gap: PropTypes.oneOf(['small', 'medium', 'large', 'xlarge']),
  verticalPad: PropTypes.oneOf(['small', 'medium', 'large', 'xlarge']),
  horizontalPad: PropTypes.oneOf(['small', 'medium', 'large', 'xlarge']),
  headingLevel: PropTypes.oneOf([
    '',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    1,
    2,
    3,
    4,
    5,
    6,
  ]),
  style: PropTypes.object,
  text: PropTypes.string,
  link: PropTypes.object,
};

export default NumberedHeading;
